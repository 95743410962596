<template>
  <div>
    <div class="p-6 text-2xl font-semibold text-gray-900">
      Models
    </div>
    <div class="flex flex-wrap">
      <template v-if="models && models.length">
        <div
          v-for="model in models"
          :key="model.id"
          class="w-full p-5 mx-6 my-2 font-semibold bg-white border rounded-md shadow cursor-pointer md:w-1/6 hover:shadow-md"
          @click="$router.push({ name: 'model', params: { id: model.id } })"
        >
          {{ model.name }}
        </div>
      </template>
      <div v-else class="w-full p-6">
        No models available
      </div>
    </div>
    <div class="p-6">
      <button class="btn" @click="createModel">Create</button>
    </div>
  </div>
</template>

<script>
import { firestore, functions } from '@/services/firebase';

export default {
  components: {},
  data() {
    return {
      models: null,
    };
  },
  created() {
    this.fetchCourses();
  },
  methods: {
    fetchCourses() {
      firestore
        .collection('models')
        .where('owner', '==', this.$store.state.user.uid)
        .onSnapshot(models => {
          this.models = [];
          models.forEach(model => this.models.push({ id: model.id, ...model.data() }));
        });
    },
    createModel() {
      functions
        .httpsCallable('createModel')({ name: 'test', description: 'test' })
        .then(result => {
          console.log(result);
        });
    },
  },
};
</script>
