<template>
  <header class="sticky top-0 h-24 bg-white shadow-md">
    <nav class="flex items-center p-4">
      <div class="flex-1">
        <div
          v-show="isAuth && !['overview', 'landing'].includes($route.name)"
          class="flex cursor-pointer"
          @click="$router.go(-1)"
        >
          <Icon class="h-6 mx-1" :path="mdiChevronLeft" />
          <div class="hidden m-w-10 md:block">
            Go back
          </div>
        </div>
      </div>

      <div class="flex justify-center flex-auto">
        <router-link :to="{ name: isAuth ? 'overview' : 'landing' }">
          <img src="/img/brand.svg" class="w-56" />
        </router-link>
      </div>

      <div class="flex justify-end flex-1">
        <div v-if="isAuth" class="flex cursor-pointer" @click="logOut()">
          <Icon class="h-6 mx-1" :path="mdiLogoutVariant" />
          <div class="hidden m-w-10 md:block">
            Log out
          </div>
        </div>

        <div
          v-else
          class="flex p-2 font-semibold cursor-pointer"
          @click="$router.push({ name: 'login' })"
        >
          <Icon class="h-6 mx-1" :path="mdiLoginVariant" />
          <div class="hidden m-w-10 md:block">
            Log in
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mdiLogoutVariant, mdiLoginVariant, mdiChevronLeft } from '@mdi/js';
import { firebaseAuth } from '@/services/firebase';

export default {
  props: { isAuth: Boolean },
  data() {
    return {
      mdiLogoutVariant,
      mdiLoginVariant,
      mdiChevronLeft,
    };
  },
  methods: {
    logOut() {
      firebaseAuth
        .signOut()
        .then(() => this.$router.replace({ name: 'login' }))
        .catch(alert);

      this.$store.commit('setUser', null);
    },
  },
};
</script>

<style scoped></style>
